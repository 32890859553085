import { useLocation } from '@remix-run/react';
import type { ReactNode } from 'react';
import React from 'react';

interface CartPopupState {
    open: boolean;
    onChange(state: boolean): void;
}

const CartPopupContext = React.createContext<CartPopupState | undefined>(
    undefined
);

export const useCartPopupState = (): CartPopupState => {
    const context = React.useContext(CartPopupContext);

    if (!context) {
        throw new Error('Cannot be used outside CartPopupContext');
    }
    return context;
};

interface Props {
    children: ReactNode;
}

export const CartPopupProvider = ({ children }: Props) => {
    const state = React.useState(false);
    const [, setOpen] = state;

    const value = React.useMemo(() => {
        return {
            open: state[0],
            onChange: state[1],
        };
    }, [state]);
    const location = useLocation();

    React.useEffect(() => {
        setOpen(false);
    }, [location, setOpen]);

    return (
        <CartPopupContext.Provider value={value}>
            {children}
        </CartPopupContext.Provider>
    );
};
